
import React from 'react';
import { TextInput, validate, minValue, NumberInput, Edit, ImageField, SimpleForm, SelectInput, RadioButtonGroupInput, required, ImageInput, useTranslate } from 'react-admin';
import {CheckboxGroupInput, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput,  
    Create, number} 
from 'react-admin';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';

import { DateInput, DateTimeInput } from 'react-admin-date-inputs2';

// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {generateRandom} from "../../util/function"
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton } from 'react-admin';
import {DeleteButton, PublishButton} from '../../control/button/index';
import {ValidateField} from '../../control/field/index';
import { withStyles } from '@material-ui/core/styles';
import {TRUE, FALSE, unitTypes} from '../../util/constant'
import Typography from '@material-ui/core/Typography';

// import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
     {typeof props.record != 'undefined' &&  typeof props.record.published != 'undefined' && props.record.published == FALSE &&
        <SaveButton />}
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
        
    </Toolbar>
);
const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
      {typeof data != 'undefined' &&  typeof data.published != 'undefined' && data.published == FALSE &&
            <PublishButton basePath={basePath} record={data} resource={resource} 
            content="Ce code remise sera envoyé aux clients via les moyens de distribution choisis. Êtes-vous sûr de vouloir publier ce code remise?"/>
        }
    {typeof data != 'undefined' &&  typeof data.published != 'undefined' && data.published === FALSE &&
            <DeleteButton content="Etes-vous sur de vouloir supprimer ce code remise?" redirect='list' basePath={basePath} record={data} resource={resource}/>
        }
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.promo_code.edit')}: {record ? `${record.name}` : ''}</span>;
};

const validatePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validateGreaterThanZero = (value, allValues) => {
    if (value < 1) {
        return 'Doit être au moins 1';
    }
    return [];
};

const validateValue = [required(), number(), validatePositive];
const validateMaxCount = [required(), number(), validateGreaterThanZero];


const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="body2">Mettez #code dans le titre ou la description si vous voulez le code apparaît dans le nom ou la description respectivement</Typography>
        
        {/* <Typography variant="body2">
            Posts will only be published once an editor approves them
        </Typography> */}
    </div>
);

const clientNameField = choice => choice != null ? `${choice.first_name} ${choice.last_name} (${choice.email})` : '';


const EditPromoCode = ({classes, permissions, ...props })  => {
    const translate = useTranslate();
    return (
        <Edit title={<Title />} {...props} actions={<EditActions/>}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
                
        <TextInput source="code" label = "Code" initialValue={generateRandom(5)} validate={required()}/>
            
        <TextInput source="name" label = "Nom" validate={required()} fullWidth={true} />
            <TextInput source="description" label = "Description" multiline="true" fullWidth={true} validate={required()}/>
            <NumberInput source="value" label = "Valeur" min={0.01} step={0.01} validate={validateValue}/>
            <RadioButtonGroupInput source="unit_type" label="Unité" choices={unitTypes}/>
            <NumberInput source="max_count" label = "Nombre maximum d'utlisation" min={1} step={1}
                validate={validateMaxCount}
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>

                <DateTimeInput source="begin_time" label="Date de début de validité"
                    options={{ format: 'DD/MM/YYYY: HH:mm' }} 
                        providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
                        validate={required()}/>
                    {/* <DateTimeInput source="begin_date" label="Date de début" 
                    options={{ format: 'DD/MM/YYYY HH:mm', ampm: false, clearable: true }} /> */}
                <DateTimeInput source="end_time" label="Date de fin  de validité" 
                    options={{ format: 'DD/MM/YYYY HH:mm', ampm: false, clearable: true }} validate={required()}/>
            </MuiPickersUtilsProvider>

            <CheckboxGroupInput source="to_all_clients" label="" choices={[
                { id: '1', name: 'Envoyer à tous les clients' },
            ]} />

            <FormDataConsumer subscription={{ values: true }}>
                 {({ formData, ...rest }) => 
                    (typeof(formData.to_all_clients) === 'undefined' ||
                    formData.to_all_clients === 'undefined' ||
                    formData.to_all_clients.length === 0 ||
                    formData.to_all_clients[0] !== '1' || 
                    formData.to_all_clients[0] !== 1) 
                    &&
                    <ReferenceArrayInput label="Clients" reference="client" source="client_ids" perPage={9999999}>
                        <AutocompleteArrayInput optionText={clientNameField}/>
                    </ReferenceArrayInput>
                 }
             </FormDataConsumer>

            <CheckboxGroupInput source="publish_to" label="Moyens de distribution" choices={[
                 { id: '1', name: 'Via emails' }, { id: '2', name: 'Via messages' },
            ]} />

            <ValidateField source="published" label="Publié"/>
             {/* <RadioButtonGroupInput source="published" label="Publié?" 
            choices={[
                { id: '0', name: 'Non' },
                { id: '1', name: 'Oui' },
            ]} initialValue='0'/> */}

        </SimpleForm>
    </Edit>
)};

export default EditPromoCode;