export const  DRIVER_STATUS_FREE = "0";
export const  DRIVER_STATUS_BUSY = "1";
export const  DRIVER_STATUS_OFFLINE = "2";



export const GENDER_MALE = "1";
export const GENDER_FEMALE = "2";

export const TRUE = 1;
export const FALSE = 0;

export const USER_TYPE_CLIENT = 1;
export const USER_TYPE_CLIENT_PRO = 12;
export const USER_TYPE_AUTO_ENTERPRENER = "2";
export const USER_TYPE_COMPANY_AND_DRIVER = "6";
export const USER_TYPE_DRIVER_IN_COMPANY = "7";
export const USER_TYPE_DRIVER_COMPANY = "246";

export const ACTIVITY_TYPE_TOURISM = "1";
export const ACTIVITY_TYPE_DELIVERY = "2";
export const ACTIVITY_TYPE_UTILITY = "3";

export const ACTIVITY_TYPE_VTC = "2";
export const ACTIVITY_TYPE_ATR = "4";

export const ORDER_ACTIVITY_TYPE_PASSENGER_ONLY = "2";
export const ORDER_ACTIVITY_TYPE_PASSENGER_WITH_ANIMAL = "3";
export const ORDER_ACTIVITY_TYPE_ANIMAL_ONLY = "4";

export const ANIMAL_TYPE_FREE = "2";
export const ANIMAL_TYPE_CAGE = "4";

// export const STATION = 1;
// export const AIRPORT = 2;
// export const AIRPORT_TERMINAL = 4;
// export const PLACE = 3;
// export const REGION = 5;
// export const CLUBS = 8;
// export const BOOK = 6;
// export const RECENT = 7;

export const weekDays = [
   'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'
];

export const vehicleColors = [
   {id:'1', name: 'Blanc'}, 
   {id:'2', name: 'Noir'}, 
   {id:'3', name: 'Gris foncé'}, 
   {id:'4', name: 'Gris argent'}, 
   {id:'5', name: 'Bleu'}, 
   {id:'6', name: 'Rouge'}, 
   {id:'7', name: 'Marron'}, 
   {id:'8', name: 'Beige'}, 
   {id:'9', name: 'Vert'}, 
   {id:'10', name: 'Jaune'}, 
   {id:'11', name: 'Orange'}, 
   {id:'12', name: 'Autres'}, 

];



export const hours = [
    {id:'1', name: '1'}, 
    {id:'2', name: '2'}, 
    {id:'3', name: '3'}, 
    {id:'4', name: '4'}, 
    {id:'5', name: '5'}, 
    {id:'6', name: '6'}, 
    {id:'7', name: '7'}, 
    {id:'8', name: '8'}, 
    {id:'9', name: '9'}, 
    {id:'10', name: '10'}, 
    {id:'11', name: '11'}, 
    {id:'12', name: '12'}, 
    {id:'13', name: '13'}, 
    {id:'14', name: '14'}, 
    {id:'15', name: '15'}, 
    {id:'16', name: '16'}, 
    {id:'17', name: '17'}, 
    {id:'18', name: '18'}, 
    {id:'19', name: '19'}, 
    {id:'20', name: '20'}, 
    {id:'21', name: '21'}, 
    {id:'22', name: '22'}, 
    {id:'23', name: '23'}, 
    {id:'24', name: '24'}, 
    
 ];

 export const minutes = [
    {id:'00', name: '00'}, 
    {id:'2', name: '01'}, 
    {id:'3', name: '02'}, 
    {id:'4', name: '03'}, 
    {id:'5', name: '04'}, 
    {id:'6', name: '05'}, 
    {id:'7', name: '06'}, 
    {id:'8', name: '07'}, 
    {id:'9', name: '08'}, 
    {id:'10', name: '10'}, 
    {id:'11', name: '11'}, 
    {id:'12', name: '12'}, 
    {id:'13', name: '13'}, 
    {id:'14', name: '14'}, 
    {id:'15', name: '15'}, 
    {id:'16', name: '16'}, 
    {id:'17', name: '17'}, 
    {id:'18', name: '18'}, 
    {id:'19', name: '19'}, 
    {id:'20', name: '20'}, 
    {id:'21', name: '21'}, 
    {id:'22', name: '22'}, 
    {id:'23', name: '23'}, 
    {id:'24', name: '24'}, 
    
 ];

export const unitTypes = [
    {id: 0, name: '$'},
    {id: 1, name: '%'}
];

export const markupTypes = [
    {id: 0, name: 'Majoration (+)'},
    {id: 1, name: 'Remise (-)'},
    
  ];

  export const countries = [
    {id: 'GN', name: 'Guinée'},
    {id: 'FR', name: 'France'},
    {id: 'DZ', name: 'Algérie'},
    {id: 'AO', name: 'Angola'},
    {id: 'BF', name: 'Burkina Faso'},
    {id: 'BJ', name: 'Bénin'},
    {id: 'BW', name: 'Botswana'},
    {id: 'BI', name: 'Burundi'},
    {id: 'CM', name: 'Cameroun'},
    {id: 'CV', name: 'Cape-Vert'},
    {id: 'KM', name: 'Comores'},
    {id: 'CG', name: 'République du Congo'},
    {id: 'CD', name: 'République démocratique du Congo'},
    {id: 'CF', name: 'République centrafricaine'},
    {id: 'CI', name: "Côte d'Ivoire"},
    {id: 'DJ', name: 'Djibouti'},
    {id: 'ER', name: 'Érythrée'},
    {id: 'ET', name: 'Éthiopie'},
    {id: 'EC', name: 'Équateur'},
    {id: 'EG', name: 'Égypte'},
    {id: 'GA', name: 'Gabon'},
    {id: 'GM', name: 'Gambie'},
    {id: 'GH', name: 'Ghana'},
    {id: 'GW', name: 'Guinée-Bissau'},
    {id: 'GQ', name: 'Guinée équatoriale'},
    {id: 'KE', name: 'Kenya'},
    {id: 'LS', name: 'Lesotho'},
    {id: 'LR', name: 'Liberia'},
    {id: 'LY', name: 'Libye'},
    {id: 'MG', name: 'Madagascar'},
    {id: 'MW', name: 'Malawi'},
    {id: 'ML', name: 'Mali'},
    {id: 'MA', name: 'Maroc'},
    {id: 'MU', name: 'Maurice'},
    {id: 'MR', name: 'Mauritanie'},
    {id: 'MZ', name: 'Mozambique'},
    {id: 'NA', name: 'Namibie'},
    {id: 'NE', name: 'Niger'},
    {id: 'NG', name: 'Nigéria'},
    {id: 'UG', name: 'Ouganda'},
    {id: 'RE', name: 'Réunion'},
    {id: 'RW', name: 'Rwanda'},
    {id: 'EH', name: 'République arabe sahraouie démocratique'},
    {id: 'ST', name: 'Sao Tomé-et-Principe'},
    {id: 'SN', name: 'Sénégal'},
    {id: 'SC', name: 'Seychelles'},
    {id: 'SO', name: 'Somalie'},
    {id: 'SD', name: 'Soudan'},
    {id: 'SS', name: 'Soudan du Sud'},
    {id: 'SZ', name: 'Eswatini'},
    {id: 'TZ', name: 'Tanzanie'},
    {id: 'TD', name: 'Tchad'},
    {id: 'TG', name: 'Togo'},
    {id: 'TN', name: 'Tunisie'},
    {id: 'ZM', name: 'Zambie'},
    {id: 'ZW', name: 'Zimbabwe'},
    {id: 'ZA', name: 'Afrique du Sud'},

    
  ];


export const OrderType = {
    IMMEDIATE: 0,
    RESERVATION: 1,
};

export const orderTypes = [
    {id: OrderType.IMMEDIATE, name: "Immédiate"},
    {id: OrderType.RESERVATION, name: "Réservation à l'avance"},
];

export const activityTypes = [
    {id: ACTIVITY_TYPE_TOURISM, name: "Tourisme"},
    {id: ACTIVITY_TYPE_DELIVERY, name: "Livraison"},
    {id: ACTIVITY_TYPE_UTILITY, name: "Utilitaire"},
];

export const deliveryTypes = [
    {id: ACTIVITY_TYPE_DELIVERY, name: "Livraison"},
    
];

export const OrderStatus = {
    CREATED: 1 << 0,
    ASSIGNED: 1 << 1,
    DENIED: 1 << 2,
    ACCEPTED: 1 << 3,
    TO_PICK: 1 << 4,
    AT_PICK: 1 << 5,
    EXTEND_WAIT: 1 << 6,
    ABSENT: 1 << 7,
    ONBOARD: 1 << 8,
    STARTED: 1 << 9,
    ENDED: 1 << 10,
    FINISHED: 1 << 11,
    CANCELLED: 1 << 12,
    UNPAID: 1 << 13,
    DELETED_BY_CLIENT: 1 << 14,
    DELETED_BY_DRIVER: 1 << 15,
    RATED_BY_CLIENT: 1 << 16,
    RATED_BY_DRIVER: 1 << 21,
    ACCEPTED_BY_OPERATOR: 1 << 17,
    DENIED_BY_OPERATOR: 1 << 18,
    WAIT_PAYMENT: 1 << 20,
}
// export const LocationType = {
//     STATION: 1,
//     AIRPORT: 2,
//     AIRPORT_TERMINAL: 4,
//     PLACE: 3,
//     REGION: 5,
//     CLUBS: 8,
//     BOOK: 6,
//     RECENT: 7,
// }

export const LocationType = {
    // STATION: 1,
    // AIRPORT: 2,
    // AIRPORT_TERMINAL: 4,
    // PLACE: 3,
    // REGION: 5,
    // CLUBS: 8,
    // BOOK: 6,
    // RECENT: 7,
    // HOTEL: 10,
    // HOSPITAL: 12,
    GARAGE: 20,
WASTE_DISPOSE: 21,
MATERIAL_DISPOSE: 22,
}

export const DataStatus = {
    NEW:"0",
    APPROVED:"1",
    REJECTED:"2",
    
}

export const Status = {
    UNLOCKED:"0",
    LOCKED:"1",
}

export const UB_DOC_TYPE_RIB = '1';
export const UB_DOC_TYPE_KBIS = '2';
export const UB_DOC_TYPE_CVTC = '3';//Card VTC
export const UB_DOC_TYPE_CVTCR = '31';
export const UB_DOC_TYPE_CVTCV = '32';
export const UB_DOC_TYPE_VEHICLE_INSURANCE = '4';
export const UB_DOC_TYPE_RCP = '5';
export const UB_DOC_TYPE_ID_CARD = '6';
export const UB_DOC_TYPE_ID_CARD_RECTO = '61';
export const UB_DOC_TYPE_ID_CARD_VERSO = '62';
export const UB_DOC_TYPE_VEHICLE_PHOTO = '8';
export const UB_DOC_TYPE_VEHICLE_FRONT = '81';
export const UB_DOC_TYPE_VEHICLE_REAR = '82';
export const UB_DOC_TYPE_DRIVING_LICENSE = '9';
export const UB_DOC_TYPE_DRIVING_LICENSE_RECTO = '91';
export const UB_DOC_TYPE_DRIVING_LICENSE_VERSO = '92';
export const UB_DOC_TYPE_SHAREHOLDER_STATEMENT = '10';
export const UB_DOC_TYPE_COMPANY_STATUS = '11';
export const UB_DOC_TYPE_VTC_REGISTER_CERT = '12';
export const UB_DOC_TYPE_PHOTO = '13';
export const UB_DOC_TYPE_VEHICLE_GRAY_CARD_RECTO = '161';
export const UB_DOC_TYPE_VEHICLE_GRAY_CARD_VERSO = '162';
export const UB_DOC_TYPE_TRANSPORT_INSURANCE = '17';
export const UB_DOC_TYPE_VEHICLE_TYPE_MAP = '181';
export const UB_DOC_TYPE_VEHICLE_TYPE_SELECT = '182';
export const UB_DOC_TYPE_INVOICE = '19';
export const UB_DOC_TYPE_EMPLOYMENT_RELATIONSHIP_PROOF = '20';
export const UB_DOC_TYPE_PRO_CARD = '21';
export const UB_DOC_TYPE_YELLOW_CARD = '22';
export const UB_DOC_TYPE_CONTINUING_TRAINING_CERT = '23';
export const UB_DOC_TYPE_TRANSPORT_LICENSE = '24';
export const UB_DOC_TYPE_AUTO_INSURANCE_CERT = '25';
export const UB_DOC_TYPE_CPAM_APPROVAL = '26';


// Date.prototype.addDays = function(days) {
//     var date = new Date(this.valueOf());
//     date.setDate(date.getDate() + days);
//     return date;
// }

// var date = new Date();