import React, { Component } from 'react';
import { GET_LIST, GET_MANY, Responsive, withDataProvider, Button, translate } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import ListIcon from '@material-ui/icons/List';
import MapIcon from '@material-ui/icons/Map';
import Divider from '@material-ui/core/Divider';
import jsonDataProvider from '../../provider/json-data-provider';
import {httpClient} from '../../util/function'

import Pusher from 'pusher-js';
import {BACKEND_UMS_URL, BACKEND_OMS_URL, PUSHER_API_KEY, PUSHER_CLUSTER,
    PUSHER_CHANNEL_USER, PUSHER_CHANNEL_ORDER, EVENT_ONLINE_DRIVERS_CHANGED, EVENT_ORDER_NEW,
} from '../../util/variant'

// import Welcome from './Welcome';
// import MonthlyRevenue from './MonthlyRevenue';
// import NbNewOrders from './NbNewOrders';
// import PendingOrders from './PendingOrders';
// import PendingReviews from './PendingReviews';
import ClientNbDashboard from './clients-nb';
import DriverNbDashboard from './drivers-nb';
import DriverOnlineNbDashboard from './drivers-online-nb';
import OrderRunningNbDashboard from './orders-running-nb';
// import DriverCompanyNbDashboard from './driver-companies-nb';
import DriverMap from './drivers-map'
import OnlineDriversDashboard from './drivers-online'

// const umsDataProvider = jsonDataProvider(BACKEND_UMS_URL, httpClient);
// const omsDataProvider = jsonDataProvider(BACKEND_OMS_URL, httpClient);

const styles = {
    flex: { display: 'flex', },
    flexColumn: { display: 'flex', flexDirection: 'column'},
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em', },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map:{ flex: 1, marginTop: '1em', marginBottom: '1em'},
    button:{ flex: 1, marginTop: '1em'},
};

class Dashboard extends Component {
    state = {
        showMap: true,
        nbClients: 0,
        nbDrivers: 0,
        nbOrders: 0,
        onlineDrivers:[],
        nbOnlineDrivers:0,
        nbUnassignedOrders:0,
    };

    componentDidMount() {
        this.fetchData();
        this.initNewOrderPusher();
        this.initOnlineDriversPusher();
    }


    initNewOrderPusher(){
        console.log('Dashboard Init new order pusher');
        const onlineDriverPusher = new Pusher(PUSHER_API_KEY, {
            cluster: PUSHER_CLUSTER,
            encrypted: true
          });
          const channel = onlineDriverPusher.subscribe(PUSHER_CHANNEL_ORDER);
          channel.bind(EVENT_ORDER_NEW, data => {
            // this.setState({ chats: [...this.state.chats, data], test: '' });
            console.log('Dashboard new order');
            
            if(localStorage.getItem('session_id'))
                this.fetchUnassignedOrders();
                this.fetchOrders();
          });
    }

    initOnlineDriversPusher(){
        console.log('Dashboard Init online driver pusher');
        const onlineDriverPusher = new Pusher(PUSHER_API_KEY, {
            cluster: PUSHER_CLUSTER,
            encrypted: true
          });
          const channel = onlineDriverPusher.subscribe(PUSHER_CHANNEL_USER);
          channel.bind(EVENT_ONLINE_DRIVERS_CHANGED, data => {
            // this.setState({ chats: [...this.state.chats, data], test: '' });
            console.log('Dashboard Online drivers changed');
            
            if(localStorage.getItem('session_id'))
                this.fetchOnlineDrivers();
          });

          
    }

    componentDidUpdate(prevProps) {
        // handle refresh
        if (this.props.version !== prevProps.version) {
            this.fetchData();
        }
    }

    changeOnlineDriverView(){
        this.setState({showMap: !this.state.showMap});
    }

    fetchData() {
        
        // this.fetchReviews();
        if(localStorage.getItem('session_id')){
            this.fetchOnlineDrivers();
            this.fetchDrivers();
            this.fetchClients();
            this.fetchOrders();
            this.fetchDriverCompanies();
        }
    }

    // async fetchOrders() {
    //     const { dataProvider } = this.props;
    //     const aMonthAgo = new Date();
    //     aMonthAgo.setDate(aMonthAgo.getDate() - 30);
    //     const { data: recentOrders } = await dataProvider(
    //         GET_LIST,
    //         'commands',
    //         {
    //             filter: { date_gte: aMonthAgo.toISOString() },
    //             sort: { field: 'date', order: 'DESC' },
    //             pagination: { page: 1, perPage: 50 },
    //         }
    //     );
    //     const aggregations = recentOrders
    //         .filter(order => order.status !== 'cancelled')
    //         .reduce(
    //             (stats, order) => {
    //                 if (order.status !== 'cancelled') {
    //                     stats.revenue += order.total;
    //                     stats.nbNewOrders++;
    //                 }
    //                 if (order.status === 'ordered') {
    //                     stats.pendingOrders.push(order);
    //                 }
    //                 return stats;
    //             },
    //             {
    //                 revenue: 0,
    //                 nbNewOrders: 0,
    //                 pendingOrders: [],
    //             }
    //         );
    //     this.setState({
    //         revenue: aggregations.revenue.toLocaleString(undefined, {
    //             style: 'currency',
    //             currency: 'USD',
    //             minimumFractionDigits: 0,
    //             maximumFractionDigits: 0,
    //         }),
    //         nbNewOrders: aggregations.nbNewOrders,
    //         pendingOrders: aggregations.pendingOrders,
    //     });
    //     const { data: customers } = await dataProvider(GET_MANY, 'customers', {
    //         ids: aggregations.pendingOrders.map(order => order.customer_id),
    //     });
    //     this.setState({
    //         pendingOrdersCustomers: customers.reduce((prev, customer) => {
    //             prev[customer.id] = customer; // eslint-disable-line no-param-reassign
    //             return prev;
    //         }, {}),
    //     });
    // }

    // async fetchReviews() {
    //     const { dataProvider } = this.props;
    //     const { data: reviews } = await dataProvider(GET_LIST, 'reviews', {
    //         filter: { status: 'pending' },
    //         sort: { field: 'date', order: 'DESC' },
    //         pagination: { page: 1, perPage: 100 },
    //     });
    //     const nbPendingReviews = reviews.reduce(nb => ++nb, 0);
    //     const pendingReviews = reviews.slice(0, Math.min(10, reviews.length));
    //     this.setState({ pendingReviews, nbPendingReviews });
    //     const { data: customers } = await dataProvider(GET_MANY, 'customers', {
    //         ids: pendingReviews.map(review => review.customer_id),
    //     });
    //     this.setState({
    //         pendingReviewsCustomers: customers.reduce((prev, customer) => {
    //             prev[customer.id] = customer; // eslint-disable-line no-param-reassign
    //             return prev;
    //         }, {}),
    //     });
    // }

    async fetchOrders() {
        const {dataProvider} = this.props;
        const { data: orders } = await dataProvider(
            GET_LIST,
            'order_running',
            {
                filter: {
                },
                sort: {},
                pagination: { page: 1, perPage: 100000 },
            }
        );
        this.setState({
            orders,
            nbOrders: orders.reduce(nb => ++nb, 0),
        });
    }

    // async fetchUnassignedOrders() {
    //     const {dataProvider} = this.props;
    //     const { data: unassignedOrders } = await dataProvider(
    //         GET_LIST,
    //         'order_unassigned',
    //         {
    //             filter: {
    //             },
    //             sort: {},
    //             pagination: { page: 1, perPage: 100000 },
    //         }
    //     );
    //     this.setState({
    //         unassignedOrders,
    //         nbUnassignedOrders: unassignedOrders.reduce(nb => ++nb, 0),
    //     });
    // }

    async fetchClients() {
        const { dataProvider } = this.props;
        
        const { data: clients } = await dataProvider(
            GET_LIST,
            'client',
            {
                filter: {
                },
                sort: {},
                pagination: { page: 1, perPage: 10000 },
            }
        );
        this.setState({
            clients,
            nbClients: clients.reduce(nb => ++nb, 0),
        });
    }

    async fetchDrivers() {
        const { dataProvider } = this.props;
        
        const { data: drivers } = await dataProvider(
            GET_LIST,
            'driver',
            {
                filter: {
                },
                sort: {},
                pagination: { page: 1, perPage: 10000 },
            }
        );
        this.setState({
            drivers,
            nbDrivers: drivers.reduce(nb => ++nb, 0),
        });
    }

    async fetchDriverCompanies() {
        const { dataProvider } = this.props;
        
        const { data: driverCompanies } = await dataProvider(
            GET_LIST,
            'driver_company',
            {
                filter: {
                },
                sort: {},
                pagination: { page: 1, perPage: 10000 },
            }
        );
        this.setState({
            driverCompanies,
            nbDriverCompanies: driverCompanies.reduce(nb => ++nb, 0),
        });
    }


    async fetchOnlineDrivers() {
        const { dataProvider } = this.props;
        
        const { data: onlineDrivers } = await dataProvider(
            GET_LIST,
            'driver_online',
            {
                filter: {
                },
                sort: {},
                pagination: { page: 1, perPage: 10000 },
            }
        );

        this.setState({
            onlineDrivers: onlineDrivers,
            nbOnlineDrivers: onlineDrivers.reduce(nb => ++nb, 0),
        });
    }

    render() {
        const {
            nbClients,
            nbDrivers,
            nbOrders,
            nbOnlineDrivers,
            onlineDrivers,
            nbDriverCompanies,
        } = this.state;
        return (
            <Responsive
                xsmall={
                    <div>
                        <div style={styles.flexColumn}>
                            
                            <div style={styles.flex}>
                                
                                <OrderRunningNbDashboard value={nbOrders} />
                                <DriverOnlineNbDashboard value={nbOnlineDrivers} />
                                
                            </div>
                            
                            <div style={styles.flex}>
                                
                                <DriverNbDashboard value={nbDrivers} />
                                <ClientNbDashboard value={nbClients} />
                            </div>
                            
                        </div>
                        <br/>
                        
                        <button style={{"background": "transparent", "border": "none", "height":"40px", "font-size":"16px", "color":"#000F84"}} id="bt" onClick={() => this.changeOnlineDriverView()}>{this.state.showMap ? 'LISTE DE JOCKEYS EN-LIGNE' : 'JOCKEYS EN-LIGNE SUR LA CARTE'} </button>
                        
                        {this.state.showMap && 
                            <div style={styles.map}><DriverMap/></div>}
                            
                        
                        {!this.state.showMap && 
                            <OnlineDriversDashboard drivers={onlineDrivers}/>
                            }
                    </div>
                }
                small={
                    <div style={styles.flexColumn}>
                        
                        <div style={styles.flex}>
                                
                                <OrderRunningNbDashboard value={nbOrders} />
                                <DriverOnlineNbDashboard value={nbOnlineDrivers} />
                                
                            </div>
                            
                            <div style={styles.flex}>
                                
                                <DriverNbDashboard value={nbDrivers} />
                                <ClientNbDashboard value={nbClients} />
                            </div>
                        <br/>
                        <div style={styles.flex}>
                        <button style={{"background": "transparent", "border": "none", "height":"40px", "font-size":"16px", "color":"#000F84"}} id="bt" onClick={() => this.changeOnlineDriverView()}>{this.state.showMap ? 'LISTE DE JOCKEYS EN-LIGNE' : 'JOCKEYS EN-LIGNE SUR LA CARTE'} </button>

                        </div>
                        
                        
                        {this.state.showMap && 
                            <div style={styles.map}><DriverMap/></div>}
                            
                        
                        {!this.state.showMap && 
                            <OnlineDriversDashboard drivers={onlineDrivers}/>
                            }
                    </div>
                }
                medium={
                    
                    <div style={styles.flexColumn}>
                        <div style={styles.flex}>
                            <div style={styles.leftCol}>
                                <div style={styles.flex}>
                                
                                
                                <OrderRunningNbDashboard value={nbOrders} />
                                <DriverOnlineNbDashboard value={nbOnlineDrivers} />
                                
                                </div>
                                
                                
                                <Button style={styles.button} label={this.state.showMap ? 'Liste de chauffeurs en-ligne' : 'Chauffeurs en-ligne sur la carte'} 
                                        onClick={() => this.changeOnlineDriverView()}>
                                    {this.state.showMap ? <ListIcon/> : <MapIcon/>}
                                </Button>
                                
                                
                            </div>
                            <div style={styles.rightCol}>
                                <div style={styles.flex}>
                                    
                                    <DriverNbDashboard value={nbDrivers} />
                                    <ClientNbDashboard value={nbClients} />
                                  
                                
                                </div>
                            </div>

                        
                        </div>
                        
                        {this.state.showMap && 
                            <div style={styles.map}><DriverMap/></div>}
                            
                        
                        {!this.state.showMap && 
                            <OnlineDriversDashboard drivers={onlineDrivers}/>
                            }
                        
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    version: state.admin.ui.viewVersion,
});

export default compose(
    connect(mapStateToProps),
    withDataProvider
)(Dashboard);
