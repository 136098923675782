import React, { Component, useState,  } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// import SettingsIcon from '@material-ui/icons/Settings';
import OrderIcon from '@material-ui/icons/LibraryBooks';
// import VehicleIcon from '@material-ui/icons/DirectionsCar';
// import VehicleIcon from '@material-ui/icons/DriveEta';
// import MessageIcon from '@material-ui/icons/Message';
import ConfigurationIcon from '@material-ui/icons/Collections';
import ReportingIcon from '@material-ui/icons/ScatterPlot';
import UserIcon from '@material-ui/icons/People';
import PriceIcon from '@material-ui/icons/Money';
import VehicleIcon from '@material-ui/icons/DriveEta';
// import PromoCodeIcon from '@material-ui/icons/LocalOffer';
// import Divider from '@material-ui/core/Divider';
// import ServiceIcon from '@material-ui/icons/ScatterPlot';
import { withRouter } from 'react-router-dom';
import {
    translate,
    DashboardMenuItem,
    MenuItemLink,
    Responsive,
    usePermissions,
    useTranslate
    
} from 'react-admin';

// import drivers from '../../component/driver';
// import clients from '../../component/client';
// import vehicleTypes from '../../component/vehicle-type';
// import vehicleBrands from '../../component/vehicle-brand';
// import vehicleModels from '../../component/vehicle-model';
// import locations from '../../component/location';
// import order from '../../component/order-running';
// import orders from '../../component/order-running';

import SubMenu from './sub-menu';

const Menu = props => {
    // state = {
    //     menuAccounts: false,
    //     menuLocations: false,
    // };

    // static propTypes = {
    //     onMenuClick: PropTypes.func,
    //     logout: PropTypes.object,
    // };
    const { permissions } = usePermissions();
    const [menuOrders, setMenuOrders] = useState(false)
    const [menuAccounts, setMenuAccounts] = useState(false)
    const [menuConfiguration, setMenuConfiguration] = useState(false)
    const [menuVehicles, setMenuVehicles] = useState(false)
    const [menuPrices, setMenuPrices] = useState(false)
    const [menuReporting, setMenuReporting] = useState(false)

    const handleToggleMenuReporting = menu => {
        setMenuReporting(!menuReporting);
    };

    const handleToggleMenuOrders = menu => {
        setMenuOrders(!menuOrders);
    };

    const handleToggleMenuAccounts = menu => {
        setMenuAccounts(!menuAccounts);
    };

    const handleToggleMenuConfiguration = menu => {
        setMenuConfiguration(!menuConfiguration);
    };

    const handleToggleMenuVehicles = menu => {
        setMenuVehicles(!menuVehicles);
    };

    const handleToggleMenuPrices = menu => {
        setMenuPrices(!menuPrices);
    };

    // render() {
        // const translate = useTranslate();
        console.log("Menu permissions: " + permissions);
        const { onMenuClick, open, logout, translate } = props;
        return (
            <div>
                {/* {' '} */}
                <DashboardMenuItem onClick={onMenuClick} />
                
                <SubMenu
                    handleToggle={() => handleToggleMenuAccounts('menuAccounts')}
                    isOpen={menuAccounts}
                    sidebarIsOpen={open}
                    name={translate(`menu.accounts`)}
                    icon={<UserIcon />}
                >
                   <MenuItemLink
                        to={`/driver`}
                        primaryText={translate(`resources.driver.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                   
                    <MenuItemLink
                        to={`/client`}
                        primaryText={translate(`resources.client.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                </SubMenu>

                <SubMenu
                    handleToggle={() => handleToggleMenuOrders('menuOrders')}
                    isOpen={menuOrders}
                    sidebarIsOpen={open}
                    name="menu.orders"
                    icon={<OrderIcon/>}
                >
                  <MenuItemLink           
                        to={`/order_running`}
                        primaryText={translate(`resources.order_running.name`, {
                            smart_count: 2,
                        })}
                        
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/order_finished`}
                        primaryText={translate(`resources.order_finished.name`, {
                            smart_count: 2,
                        })}
                        
                        onClick={onMenuClick}
                    />
                    
                </SubMenu>

               
                <SubMenu
                    handleToggle={() => handleToggleMenuVehicles('menuVehicles')}
                    isOpen={menuVehicles}
                    sidebarIsOpen={open}
                    name="menu.vehicles"
                    icon={<VehicleIcon/>}
                    
                >
                {permissions === "Admin" && <MenuItemLink
                        to={`/vehicle_type`}
                        primaryText={translate(`resources.vehicle_type.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />}
                  {permissions === "Admin" && <MenuItemLink
                        to={`/vehicle_brand`}
                        primaryText={translate(`resources.vehicle_brand.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />}
                    {permissions === "Admin" && <MenuItemLink
                        to={`/vehicle_model`}
                        primaryText={translate(`resources.vehicle_model.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />}
                     <MenuItemLink
                        to={`/vehicle`}
                        primaryText={translate(`resources.vehicle.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                    
                </SubMenu>

                {permissions === "Admin" && <SubMenu
                    handleToggle={() => handleToggleMenuPrices('menuPrices')}
                    isOpen={menuPrices}
                    sidebarIsOpen={open}
                    name="menu.prices"
                    icon={<PriceIcon/>}
                    
                >
                    <MenuItemLink
                        to={`/price`}
                        primaryText={translate(`resources.price_base.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                  
                    <MenuItemLink
                        to={`/price_time`}
                        primaryText={translate(`resources.price_time.name`, {
                            smart_count: 2,
                        })}
                        // leftIcon={<vehicleTypes.icon />}
                        onClick={onMenuClick}
                    />
                  
                    <MenuItemLink
                        to={`/promo_code`}
                        primaryText={translate(`resources.promo_code.name`, {
                            smart_count: 2,
                        })}
                        // leftIcon={<PromoCodeIcon/>}
                        onClick={onMenuClick}
                    />
                    
                </SubMenu>}

                {permissions === "Admin" && <SubMenu
                    handleToggle={() => handleToggleMenuReporting('menuReporting')}
                    isOpen={menuReporting}
                    sidebarIsOpen={open}
                    name="menu.reporting"
                    icon={<ReportingIcon/>}
                    
                >
                    <MenuItemLink
                        to={`/rate_system`}
                        primaryText={translate(`resources.rate_system.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                  
                    
                    
                </SubMenu>}

                {permissions === "Admin" && <SubMenu
                    handleToggle={() => handleToggleMenuConfiguration('menuConfiguration')}
                    isOpen={menuConfiguration}
                    sidebarIsOpen={open}
                    name="menu.configuration"
                    icon={<ConfigurationIcon/>}
                    
                >
                

                    <MenuItemLink
                        to={`/location`}
                        primaryText={translate(`resources.location.name`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />
                  

                    <MenuItemLink
                        to={`/email_template`}
                        primaryText={translate(`resources.email_template.name`, {
                            smart_count: 2,
                        })}

                        onClick={onMenuClick}
                    />

                    {/* <MenuItemLink
                        to={`/sms_template`}
                        primaryText={translate(`resources.sms_template.name`, {
                            smart_count: 2,
                        })}

                        onClick={onMenuClick}
                    /> */}
                     <MenuItemLink
                        to={`/config`}
                        primaryText={translate(`menu.settings`, {
                            smart_count: 2,
                        })}
                        onClick={onMenuClick}
                    />


                </SubMenu>}

                {/* {permissions === "Client" && <SubMenu
                    handleToggle={() => handleToggleMenuConfiguration('menuConfiguration')}
                    isOpen={menuConfiguration}
                    sidebarIsOpen={open}
                    name="menu.help"
                    icon={<ConfigurationIcon/>}
                    
                ></SubMenu>} */}
                
                {/* <Responsive
                    xsmall={
                        <MenuItemLink
                            to="/configuration"
                            primaryText={translate('pos.configuration')}
                            leftIcon={<SettingsIcon />}
                            onClick={onMenuClick}
                        />
                    }
                    medium={null}
                /> */}
                {permissions === "Shipper" && <MenuItemLink           
                        to={`/order_shipper`}
                        primaryText={translate(`resources.order.list`, {
                            smart_count: 2,
                        })}
                        leftIcon={<OrderIcon/>}
                        onClick={onMenuClick}
                    />}
                {/* <SubMenu> */}
                    {/* <MenuItemLink           
                        to={`/order-shipper`}
                        primaryText={translate(`resources.order.list`, {
                            smart_count: 2,
                        })}
                        
                        onClick={onMenuClick}
                    /> */}
                    
                {/* </SubMenu>} */}
                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    // }
}

// class Menu extends Component {
//     state = {
//         menuAccounts: false,
//         menuLocations: false,
//     };

//     static propTypes = {
//         onMenuClick: PropTypes.func,
//         logout: PropTypes.object,
//     };

//     handleToggle = menu => {
//         this.setState(state => ({ [menu]: !state[menu] }));
//     };

//     render() {
//         const { onMenuClick, open, logout, translate, permissions } = this.props;
//         return (
//             <div>
//                 {' '}
//                 <DashboardMenuItem onClick={onMenuClick} />
//                 <SubMenu
//                     handleToggle={() => this.handleToggle('menuOrders')}
//                     isOpen={this.state.menuOrders}
//                     sidebarIsOpen={open}
//                     name="menu.orders"
//                     icon={<OrderIcon/>}
//                 >
//                 {permissions === "Client" &&
//                     <MenuItemLink
//                         to={`/order/create`}
//                         primaryText={translate(`resources.order.create`, {
//                             smart_count: 2,
//                         })}
                        
//                         onClick={onMenuClick}
//                     />}


//                     <MenuItemLink
//                         to={`/order_unassigned`}
//                         primaryText={translate(`resources.order_unassigned.list`, {
//                             smart_count: 2,
//                         })}
                        
//                         onClick={onMenuClick}
//                     />

//                     <MenuItemLink           
//                         to={`/order`}
//                         primaryText={translate(`resources.order.list`, {
//                             smart_count: 2,
//                         })}
                        
//                         onClick={onMenuClick}
//                     />
                    
//                 </SubMenu>
//                 <SubMenu
//                     handleToggle={() => this.handleToggle('menuAccounts')}
//                     isOpen={this.state.menuAccounts}
//                     sidebarIsOpen={open}
//                     name={translate(`menu.accounts`)}
//                     icon={<UserIcon />}
//                 >
//                     <MenuItemLink
//                         to={`/client`}
//                         primaryText={translate(`resources.client.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     />
//                     <MenuItemLink
//                         to={`/shipper`}
//                         primaryText={translate(`resources.shipper.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     />
//                     <MenuItemLink
//                         to={`/receiver`}
//                         primaryText={translate(`resources.receiver.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     />
//                 </SubMenu>

                
                

//                 <SubMenu
//                     handleToggle={() => this.handleToggle('menuInvoices')}
//                     isOpen={this.state.menuInvoices}
//                     sidebarIsOpen={open}
//                     name="menu.invoices"
//                     icon={<PriceIcon/>}
                    
//                 >
//                     <MenuItemLink
//                         to={`/invoice`}
//                         primaryText={translate(`resources.invoice_monthly.name`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     />
                    
                    
//                 </SubMenu>

//                 <SubMenu
//                     handleToggle={() => this.handleToggle('menuConfiguration')}
//                     isOpen={this.state.menuConfiguration}
//                     sidebarIsOpen={open}
//                     name="menu.configuration"
//                     icon={<ConfigurationIcon/>}
                    
//                 >
//                     <MenuItemLink
//                         to={`/config`}
//                         primaryText={translate(`menu.settings`, {
//                             smart_count: 2,
//                         })}
//                         onClick={onMenuClick}
//                     />
//                     <MenuItemLink
//                         to={`/email_template`}
//                         primaryText={translate(`resources.email_template.name`, {
//                             smart_count: 2,
//                         })}

//                         onClick={onMenuClick}
//                     />

//                     <MenuItemLink
//                         to={`/sms_template`}
//                         primaryText={translate(`resources.sms_template.name`, {
//                             smart_count: 2,
//                         })}

//                         onClick={onMenuClick}
//                     />
                    


//                 </SubMenu>
                
//                 {/* <Responsive
//                     xsmall={
//                         <MenuItemLink
//                             to="/configuration"
//                             primaryText={translate('pos.configuration')}
//                             leftIcon={<SettingsIcon />}
//                             onClick={onMenuClick}
//                         />
//                     }
//                     medium={null}
//                 /> */}
//                 <Responsive
//                     small={logout}
//                     medium={null} // Pass null to render nothing on larger devices
//                 />
//             </div>
//         );
//     }
// }

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    // theme: state.theme,
    // locale: state.i18n.locale,
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);

export default enhance(Menu);
