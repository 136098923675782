import React from 'react';

const Logo = props => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
  x="0px" y="0px" width="50px" height="50px"
   viewBox="0 0 50 50" enable-background="new 0 0 50 50" >  
   <image id="image0" width="50" height="50" x="0" y="0"
    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAChVBMVEX///8ABIoABIoABIoA
BIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoA
BIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoA
BIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoA
BIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoA
BIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoA
BIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoA
BIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoA
BIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoA
BIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoA
BIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoA
BIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoABIoA
BIr///9w0iPgAAAA1XRSTlMAAClMMRYEf2s1MElWXFE+Hroohdv9zpFGwlAn+fCqhHaGxL6WSHKk
ghCBn5RsxkBxOI9vGbk36dMTZGAPOoMJbaX8V14jt+B7IUozBUOoLAyhTusGR6wRFCIdDQEIyyqT
waD6+27u4+Lsr3qp1gLXFc+nK6PFav5mpvX31cobGi4mL/FBRd3RgOiaPTQ7Y3C8YgfZLdwl3gtV
Qto57fPUJEQSfL8yrfLk58e7S3SwU/RbPBeI0PiJCiCzjd+ueGhdw2dZwJJaWByVfo4/YYxNneGx
c2kx+O8EAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB+YHHBELDSSH
CjkAAAPselRYdFJhdyBwcm9maWxlIHR5cGUgeG1wAABIib1XXdKjOAx81yn2CFiyZXMcEsPbVM3j
Hn+75XwhCWQm8+3WhgoQI0ut1h+Rv3/8lL/wSTWp2NW22urkyc0vXmrWydWLV599ta66bpfLZVPF
+uyZK6Vayd2m3OuUDbLNZ8mtLhUbi9UlryU7rlBohk2qttmqk11rs6U2x0bvNOZJJ/72q6/V+Exo
AWiyb8Rhy3hwFw8kuxqsXbgj33foVFruZRIluK3GkrmuNmkHngkHVFi1GWvJ3DIOx9MrVtXiqxuu
BedkKtpDbMFSxjlZ1enl0Jt7ChSQLJpz9hfXVOIh3Ws145hsgTtbjY+uFVK6BuIalmcegURxVpz7
MABE1SriQ0Zqg1uwwOfPKAABoUIg1OdgagZDkPh67klA2FZBLFENYh9jQYKPeMPYuocI9ya47XCl
AQ/JS0H39qoSW+yEgAkQ1K90Xob3FMS5YGNh6OkqrA7F6agY+dTgCRDBYd5N8hnwG+7D9t0HOecl
d5TAn5ho8swNFPYTR6zmXKqPkJwrlzPtz8pZoRVBh8xWNNJgC5JRrlQ/GBXmzLBPG5mZUqCIPGih
kc2vh+o6cgWyNSxDmaL4opmM9Awhnd+YKTmPK42w7mSkOVK+4qzWSkYhoNqjRHkueokSZTVOT2q7
M21m9zrnLM9636pFu4qzvlMr+dZ5fEBNr5A/VS277iiDxT1qvVNdBHvmPRpviYRcUQfEcZLZCxsb
Mgbbb14PNHa+AfIze1xh5W8wO7OsrCOzkSeKDMM2iAA5SGbfQfjoTNTanYvIeKhiGgYha2QTyab1
IYSUnGNOLGj8idxAXUHmUGHDNVnGHeG0uGtIhQIznXJiTlF+jU5FZz4gYCpavfcFIngdGqNEbCA5
YwSxKTZBIkOCo6BHLKcxJIZ5ohNLwGO4rQTMPAc7RozG2XbkhzGdx/gEousXWnmFe+cr3/hqUA1j
YMuwsURO7XwpB9VAVCFOwEgtfJ1+cw2IGpXcx8AjKozoiBrQ1Xk8l/u0Qhhrep4dyJAZju3daGFR
f82QoPtexoJLDh4exU/y5DRNHtyWX/n9e7d3AEIEtTqTa/1tob5vLSa7Q2yh6C0ZahM9z1GqjOh/
1SG3T0IgjzHA1hTN/Bv0y41/heeYE8joZCy27hpvHkH/LdV7VFWxxjrAGjqN3zh1NDbWTXtF8DH9
9z4pR/6/R78c+f9oQD3yx52YtGtYGe9H26cxOobo35XIQyeVT1vp2076rRJ5Pyb/3xI5idExRDJi
pNtZg4t3pV/2xX2fnL9BH9TH2/6u/vUvjq3CRyd/tMDNAM5Rz/9I8g8n/xqqj9Ee6AAAA1BJREFU
SMfd1vk/FGEYAHCPsqnF5soSyhFJpdy7O+um0m5Jy7apdAiLQo5uhaXkKIW2UpF0n6v7okuppGv+
n95nbPaawQ99Pn0+PT/MzPs873fmnXnfmV0rq/8ogIT1lKk2MOkghDfNdvoMvp29g2DmJImNo52T
swvNhOssN+EkiLvHbE/s7uU9Z66Pt+8sP/95E5CAQGAuMD9InxEsCF64KGg8sjjEDcUS4+TSaaFh
4dwkIjKKiGiztEg8X0JxkUApXiTGohBrF8fnIPHSBEJsWUpUYlIyK0mRLiNkOev5VqSulLEQP3kg
Ias4xm29Os2SrIlLJ2Qt5/NRWJKMTCXeP3Or61TrDaHKgiyFYgNvo0KhSDOGVrApezMhW7Cxld6W
Mxb0zO25eXl5+flkoy4wIYX+MrzMStIooo1iB8QbGtEmZGdxCc5/KWnsMiZlUG5oVJgQqPSG3SS7
B2AvU963H7dFOM6xOGBK4KBUgOkqOHS4mkRNLbZKRkltuqauri410oyU1TNDOqJPHUXhDKOkgWUq
cVNYBA2kfIzJqFDU8vTEq7GmqWl6s9KcQEoLDzsex+MTeNQKYHIvfAty8lTbVKwkAghx3w5mRG5B
oOO09gyWzkI+7kLMSYYlgcZzUIm1zrbd+qUwSlzPR6nVuRc0LAQ8LsIiNNZaT7rLQHKrtBRFiRRs
BM5EgxhN96UefQZnX83xkEfD4QCVrv9ylFEy2UYIZn8rjAhoLit70VxR8K8mXLvu1EOOt1Vcdnd3
v3EziJ3ALTo5DZ9YfXIvWTRNt40WqQ0HuYMzk4k97kIMLQ8wiHsidsLHYoHsPrOQebrZRvNC97GT
ZA+hUPPAT6B6SPo8cgCVpEUfYkksx8CYqPF9TIXhiUt7gSWoJ1Ys2VBH3dMIRM86LYvyXjYCzTef
qzJeIHqpMa/1RLISgFcp/eED+A7Rr9+IjAtv38VyEFC+33W3Y5D5cfMq//DxT3qQjuvjIgBObkOD
2XJmluic0jWfxAO2Q+303kv23IR8xZ0/t35pkjxLMEyPjyygazwCoB3+Gjai0428+lbh8r34R6H8
bEwkjE9IVA///JXYLcxIHbmVOq+/sAomJiRKut/uaZUMJ0nsnLD5r//c/M34Derh3hiuFoBPAAAA
JXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA3LTI4VDE3OjExOjEzKzAwOjAw/K9iYgAAACV0RVh0ZGF0
ZTptb2RpZnkAMjAyMi0wNy0yOFQxNzoxMToxMyswMDowMI3y2t4AAAAASUVORK5CYII=" />
</svg>
);

export default Logo;
