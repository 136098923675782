
import React from 'react';
import { TextInput, FileInput, RadioButtonGroupInput, ImageField, PasswordInput, SelectInput, FormDataConsumer, Create, SimpleForm, required, useTranslate, email} from 'react-admin';
// import { DateInput } from 'react-admin-date-inputs2';
import { DateInput } from 'react-admin-date-inputs2';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {countries} from '../../util/constant';
// import {locationTypes} from '../../util/variant';
import MomentUtils from '@date-io/moment';
DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

const validateConfirmPassword = (value, allValues) => {
    if (value !== allValues.password) {
        return 'Mots de passe ne sont pas identiques';
    }
    return [];
};

// const validateMobileNumber = (value, allValues) => {
//     if (!value.startsWith('33') || value.length !== 12) {
//         return "Doit être 12 chiffres sous forme '330xxxxxxxxx'";
//     }
//     return [];
// };
const validateMobileNumber = (value, allValues) => {
    if (!value.startsWith('224')) {
        return "Doit être sous forme '224xxxxxxxxx'";
    }
    // if (value.length !== 10) {
    //     return "Doit être 10 chiffres";
    // }
    return [];
};

const validatePassword = [required(), validateConfirmPassword];
const validateEmail = [required(), email()];
const validateMobile = [required(), validateMobileNumber];
// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.create')}</span>;
};

const CreateDriver = props => (
    <Create title={<Title/>} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="last_name" label="Nom" validate={required()}/>
            <TextInput source="first_name" label="Prénom" validate={required()}/>
            <TextInput source="email" label="Email" validate={required()}/>
            <TextInput source="mobile" label="Mobile" validate={required()}/>
            {/* <SelectInput source="type" label="Type" validate={required()} choices={locationTypes}/> */}
            <MuiPickersUtilsProvider utils={MomentUtils}>
            {/* <DateInput source="birth_day" label="Date de naissance"
            autoComplete="" options={{ format: 'dd/MM/yyyy' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            /> */}

            <DateInput source="birth_date" label="&nbsp;&nbsp;&nbsp;Date de naissance"
                    options={{ format: 'DD/MM/YYYY' }} 
                        providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
                        validate={required()}/>

            </MuiPickersUtilsProvider>
            {/* <DateInput source="birth_day" label="Date de naissance"
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            /> */}
            <TextInput source="address" label="Adresse ou quartier" validate={required()}/>
            <TextInput source="city" label="Ville" validate={required()}/>
            {/* <TextInput source="country" label="Pays" validate={required()}/> */}
            <SelectInput label="Pays" source="country" choices={countries} 
                // optionText="name" optionValue="id"
            />

            <PasswordInput source="password" label="Mot de passe" validate={required()}/>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <PasswordInput {...rest} source="password_confirm" label="Mot de passe confirmé" validate={validatePassword}/>
                     
                }
            </FormDataConsumer>

            <RadioButtonGroupInput source="payment_type_id" label="Mode de paiement" options={{row: true}}
            choices={[{ id: '0', name: 'Compte bancaire' }, { id: '1', name: 'Espèces' }, { id: '2', name: 'Mobile banking' },
            ]} initialValue='0'/>  

            <FormDataConsumer subscription={{ values: true }}>
                 {({ formData, ...rest }) => 
                    (
                    formData.payment_type_id === '0' ||
                    formData.payment_type_id === '2' 
                    ) &&
                    <TextInput label="Ref. mode de paiement" source="payment_type_value"/>
                        
                 }
             </FormDataConsumer>
            
            
            
            
            <FileInput source="photo" label="Photo" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            {/* <FileInput source="id_card" label="Carte d'identité" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            <FileInput source="vtc_card" label="Carte VTC" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>       */}

            <FileInput source="driving_license" label="Permis de conduire" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            <FileInput source="id_card" label="Pièce d'identité" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>    

              

            {/* <FileInput source="vtc_register_cert" label="Certificat d'inscription au registre VTC" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      */}
        </SimpleForm>
    </Create>
);

export default CreateDriver;

{/* options={{ format: 'dd/MM/yyyy' }  */}